import {
	Answer as Answers,
	Chapter as ChapterType,
	Question as QuestionType,
} from '../../types';

const isAnswered = (
	questionId: string,
	values?: { [key: string]: Answers }
): boolean => {
	const answer = values?.[questionId];
	if (!answer) {
		return false;
	}
	if (typeof answer === 'string') {
		return (answer as string).trim() !== '';
	} else if (Array.isArray(answer)) {
		return answer.length > 0;
	}
	return false;
};

const getNextQuestionId = (
	chapters: ChapterType[],
	question: QuestionType,
	direction: boolean
): string | null => {
	const chapterIndex = chapters.findIndex(
		(chapter: ChapterType) => chapter.chapterId === question.chapterId
	);
	const chapter = chapters[chapterIndex];
	const questionId = question.questionId;
	const currentQuestionIndex = (chapter.questions || []).findIndex(
		(question) => question.questionId === questionId
	);

	if (currentQuestionIndex === -1) {
		return null;
	}

	if (direction) {
		if (currentQuestionIndex < (chapter.questions || []).length - 1) {
			return (chapter.questions || [])[currentQuestionIndex + 1].questionId;
		} else {
			return getFirstQuestionOfNextChapter(chapters, chapterIndex);
		}
	} else {
		if (currentQuestionIndex > 0) {
			return (chapter.questions || [])[currentQuestionIndex - 1].questionId;
		} else {
			return getLastQuestionOfPreviousChapter(chapters, chapterIndex);
		}
	}
};

const getFirstQuestionOfNextChapter = (
	chapters: ChapterType[],
	currentChapterIndex: number
): string | null => {
	for (let i = currentChapterIndex + 1; i < chapters.length; i++) {
		if ((chapters[i].questions || []).length > 0) {
			return (chapters[i].questions || [])[0].questionId;
		}
	}
	return null;
};

const getLastQuestionOfPreviousChapter = (
	chapters: ChapterType[],
	currentChapterIndex: number
): string | null => {
	for (let i = currentChapterIndex - 1; i >= 0; i--) {
		if ((chapters[i].questions || []).length > 0) {
			return (chapters[i].questions || [])[
				(chapters[i].questions || []).length - 1
			].questionId;
		}
	}
	return null;
};

const getNextUnansweredQuestionId = (
	chapters: ChapterType[],
	question: QuestionType,
	direction: boolean,
	answers?: { [key: string]: Answers }
): string | null => {
	while (true) {
		const nextQuestionId = getNextQuestionId(chapters, question, direction);
		if (!nextQuestionId) {
			return null;
		}

		if (!isAnswered(nextQuestionId, answers)) {
			return nextQuestionId;
		}

		const nextChapter = chapters.find((chapter) =>
			(chapter.questions || []).some((q) => q.questionId === nextQuestionId)
		);
		if (!nextChapter) {
			return null;
		}
		question = nextChapter.questions?.find(
			(q) => q.questionId === nextQuestionId
		)!;
	}
};

const getFirstUnansweredQuestionInChapter = (
	chapter: ChapterType,
	answers?: { [key: string]: Answers }
): string | null => {
	for (let i = 0; i < (chapter.questions || []).length; i++) {
		if (
			!answers ||
			isAnswered((chapter.questions || [])[i].questionId, answers)
		) {
			return (chapter.questions || [])[i].questionId;
		}
	}
	return null;
};

const getFirstQuestionOfChapter = (
	chapters: ChapterType[],
	startIndex: number,
	direction: boolean
): string | null => {
	if (direction) {
		for (let i = startIndex + 1; i < chapters.length; i++) {
			if ((chapters[i].questions || []).length > 0) {
				return (chapters[i].questions || [])[0].questionId;
			}
		}
	} else {
		for (let i = startIndex - 1; i >= 0; i--) {
			if ((chapters[i].questions || []).length > 0) {
				return (chapters[i].questions || [])[
					(chapters[i].questions || []).length - 1
				].questionId;
			}
		}
	}
	return null;
};

export {
	isAnswered,
	getNextQuestionId,
	getFirstQuestionOfChapter,
	getNextUnansweredQuestionId,
	getFirstUnansweredQuestionInChapter,
};
