import CoverImage from 'components/shared/CoverImage/CoverImage';
import { Checklist as ChecklistType } from '../../types';
import { ReactComponent as CommentIcon } from '../icons/comment.svg';
import { ReactComponent as EyeIcon } from '../icons/eye.svg';
import { ReactComponent as SendIcon } from '../icons/mail.svg';
import styles from './ResponsePage.module.scss';

export default function NoResponsePage({
	checklist,
	openComments,
	setOpenInvitePopup,
}: {
	checklist?: ChecklistType;
	openComments: () => void;
	setOpenInvitePopup: (value: boolean) => void;
}) {
	return (
		<div className={styles.noResponsePage}>
			<div className={styles.noResponseNav}>
				<button className={styles.noBorderButton}>
					<EyeIcon />
				</button>
				<button className={styles.noBorderButton} onClick={openComments}>
					<CommentIcon />
				</button>
			</div>
			<div className={styles.noResponseContainer}>
				{/* <div className={styles.navButtons}>
				<button className={styles.navButton}>
					<CommentIcon />
					{comments}
				</button>
				<button className={styles.navButton}>
					<EyeIcon />
					{views}
				</button>
			</div> */}
				<div className={styles.content}>
					<div className={styles.cover}>
						{/* <img src={checklist?.coverImage} alt={checklist?.title} /> */}
						<CoverImage
							id={checklist?.checklistId}
							coverImage={checklist?.coverImage}
							width="100%"
							height="10rem"
						/>
					</div>
					<h2>Aucune réponse pour le moment</h2>
					<div className={styles.kicker}>
						Partagez la Checklist pour obtenir les réponses dont vous avez
						besoin
					</div>
					<button
						className={styles.primaryButton}
						onClick={() => setOpenInvitePopup(true)}
					>
						<SendIcon />
						Inviter à remplir
					</button>
				</div>
			</div>
		</div>
	);
}
