import { useRef, useState, useEffect } from 'react';
import styles from './PdfReport.module.scss';
import { Answer as Answers, Chapter as ChapterType } from 'types';
import { ReactComponent as ArrowIcon } from '../icons/textArrow.svg';
import { ReactComponent as ErrorIcon } from '../icons/error.svg';
import { ReactComponent as FilledCheckIcon } from '../icons/check_green.svg';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Avatar, { getColorForId } from 'components/Avatar/Avatar';

function getType(type: string) {
	switch (type) {
		case 'multipleSelect':
			return 'Sélection multiple';
		case 'singleSelect':
			return 'Sélection unique';
		case 'text':
			return 'Texte';
		case 'number':
			return 'Nombre';
		case 'dateSelect':
			return 'Date';
		case 'file':
			return 'Fichier';
		case 'media':
			return 'Media';
		default:
			return '';
	}
}

const MAX_SUMMARY_HEIGHT = 360;

export default function PdfReport({
	response,
	answers,
	hasSpacing,
}: {
	response: {
		state: any;
		timestamp: string;
		responseId: string;
		modifiedBy: { firstName: string; lastName: string }[];
	};
	answers: { [key: string]: Answers };
	hasSpacing?: boolean;
}) {
	const [summaryPages, setSummaryPages] = useState<any[][]>([]);
	const summaryRef = useRef<HTMLDivElement>(null);
	const chapterRefs = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(() => {
		if (summaryRef.current && chapterRefs.current.every((ref) => ref)) {
			let currentPageHeight = 0;
			let currentPage: any[] = [];
			const pages: any[][] = [];

			response.state.chapters.forEach((chapter: ChapterType, index: number) => {
				const chapterHeight = chapterRefs.current[index]?.clientHeight || 0;

				if (currentPageHeight + chapterHeight > MAX_SUMMARY_HEIGHT) {
					pages.push(currentPage);
					currentPage = [chapter];
					currentPageHeight = chapterHeight;
				} else {
					currentPage.push(chapter);
					currentPageHeight += chapterHeight;
				}
			});

			if (currentPage.length > 0) {
				pages.push(currentPage);
			}

			setSummaryPages(pages);
		}
	}, [response.state.chapters]);

	const flattenQuestions = response.state.chapters.reduce(
		(acc: any, chapter: any) => {
			chapter.questions?.forEach((question: any) => {
				const answer = answers[question.questionId];
				if (question.type === 'media' && Array.isArray(answer)) {
					// If the answer is an array (multiple images), iterate over it
					(answer as string[]).forEach((mediaAnswer: string) => {
						acc.push({ ...question, answer: mediaAnswer });
					});
				} else {
					acc.push(question);
				}
			});
			return acc;
		},
		[]
	);

	const userColor = response.modifiedBy[0]
		? getColorForId(
				`${response.modifiedBy[0].firstName} ${response.modifiedBy[0].lastName}`
		  )
		: '#4369ef';

	const calculatePage = (index: number) => {
		const chapter = response.state.chapters[index];
		const question = chapter.questions?.[0];
		const questionIndex = flattenQuestions.findIndex(
			(q: any) => q.questionId === question?.questionId
		);
		return questionIndex + 2;
	};

	const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

	const scrollToPage = (chapterIndex: number) => {
		const pageIndex = calculatePage(chapterIndex) - 1;
		pageRefs.current[pageIndex]?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div
			className={`${styles.pdfReport} ${hasSpacing ? styles.pageSpacing : ''}`}
		>
			{/* Hidden shadow list for measurements */}
			<div
				style={{
					position: 'absolute',
					visibility: 'hidden',
					overflow: 'hidden',
				}}
				className={styles.pdfPage}
			>
				<div ref={summaryRef} className={`${styles.page} ${styles.summary}`}>
					<div className={styles.chapters}>
						{response.state.chapters.map((chapter: any, index: number) => (
							<div
								key={`shadow-chapter-${chapter.chapterId}`}
								ref={(el) => (chapterRefs.current[index] = el)}
								className={styles.chapterFlex}
							>
								<div className={styles.chapterColumn}>
									<div className={styles.chapterNumber}>
										Chapitre {index + 1}
									</div>
									<div className={styles.chapterTitle}>{chapter.title}</div>
									<div className={styles.chapterContext}>{chapter.context}</div>
								</div>
								<div className={styles.chapterPage}>
									p{calculatePage(index)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Actual summary pages */}
			{summaryPages.map((page, pageIndex) => (
				<div className={styles.pdfPage} key={`summary-page-${pageIndex}`}>
					<div className={`${styles.page} ${styles.summary}`}>
						{response.state.coverImage ? (
							<img
								className={styles.checklistCoverColorImage}
								src={response.state.coverImage}
								alt="Checklist cover"
							/>
						) : (
							<div
								className={styles.checklistCoverColorImage}
								style={{ background: userColor }}
							/>
						)}
						<div className={styles.finishedFlex}>
							Terminée
							<FilledCheckIcon />
						</div>
						<div className={styles.summaryFlex}>
							<div className={styles.summaryTitle}>{response.state.title}</div>
							<div className={styles.validated}>Validée</div>
						</div>
						<div className={styles.summaryFlex}>
							{response.modifiedBy[0] && (
								<Avatar
									name={`${response.modifiedBy[0].firstName} ${response.modifiedBy[0].lastName}`}
									color={userColor}
								/>
							)}
							{response.modifiedBy[0] && (
								<div className={styles.user}>
									Remplie par{' '}
									<span>
										{response.modifiedBy[0].firstName}{' '}
										{response.modifiedBy[0].lastName}
									</span>{' '}
									le{' '}
									{format(new Date(response.timestamp), 'd MMMM yyyy', {
										locale: fr,
									})}
								</div>
							)}
							{response.modifiedBy[1] && (
								<div>
									Validée par{' '}
									<span>
										{response.modifiedBy[1].firstName}{' '}
										{response.modifiedBy[1].lastName}
									</span>
									le{' '}
									{format(new Date(response.timestamp), 'd MMMM yyyy', {
										locale: fr,
									})}
								</div>
							)}
						</div>
						<div className={styles.summaryNumber}>
							Chapitre {pageIndex + 1} / {summaryPages.length}
						</div>
						<div className={styles.chapters}>
							{page.map((chapter: any, index: number) => (
								<div
									key={`chapter-summary-${chapter.chapterId}`}
									className={styles.chapterFlex}
									onClick={() =>
										scrollToPage(
											index +
												(pageIndex > 0
													? summaryPages
															.slice(0, pageIndex)
															.reduce((acc, page) => acc + page.length, 0)
													: 0)
										)
									}
									style={{ cursor: 'pointer' }}
								>
									<div className={styles.chapterColumn}>
										<div className={styles.chapterNumber}>
											Chapitre {index + 1}
										</div>
										<div className={styles.chapterTitle}>{chapter.title}</div>
										<div className={styles.chapterContext}>
											{chapter.context}
										</div>
									</div>
									<div className={styles.chapterPage}>
										p
										{calculatePage(
											index +
												(pageIndex > 0
													? summaryPages
															.slice(0, pageIndex)
															.reduce((acc, page) => acc + page.length, 0)
													: 0)
										)}
									</div>
								</div>
							))}
						</div>
						<div className={styles.footer}>
							<div>
								{pageIndex + 1} /{' '}
								{flattenQuestions.length + summaryPages.length}
							</div>
						</div>
					</div>
				</div>
			))}

			{flattenQuestions.map((question: any, index: number) => {
				const chapter = response.state.chapters.find(
					(chapter: any) => chapter.chapterId === question.chapterId
				);
				const chapterIndex = response.state.chapters.findIndex(
					(chapter: any) => chapter.chapterId === question.chapterId
				);
				const chapterTotal = response.state.chapters.length;
				const answer = question.answer || answers[question.questionId];

				return (
					<div
						key={`${question.questionId}-${index}`}
						className={styles.pdfPage}
						ref={(el) => (pageRefs.current[index + 1] = el)}
					>
						<div className={styles.page}>
							<div className={styles.simpleColumn}>
								<div className={styles.header}>
									{response.state.coverImage ? (
										<img
											src={response.state.coverImage}
											alt="Checklist cover"
										/>
									) : (
										<div
											className={styles.checklistColorImage}
											style={{ background: userColor }}
										/>
									)}
									<div className={styles.column}>
										<div>{response.state.title}</div>
										<div className={styles.spacedFlex}>
											{chapter.title}

											<div className={styles.greyText}>
												Chapitre {chapterIndex + 1} / {chapterTotal}
											</div>
										</div>
									</div>
								</div>

								{chapter.context && (
									<div className={`${styles.greyText} ${styles.textColumn}`}>
										<span>Contexte du chapitre</span>
										<div>{chapter.context}</div>
									</div>
								)}
							</div>

							<div className={styles.content}>
								<div className={styles.blueContainer}>
									<div className={styles.greyText}>
										{getType(question.type)}
									</div>
									<div className={styles.title}>{question.title}</div>
									{(question.type === 'singleSelect' ||
										question.type === 'multipleSelect') && (
										<div className={styles.greyText}>
											Autres choix possibles :{' '}
											<span>
												{question.singleSelect?.options
													?.filter((option: any) => option !== answer)
													.join(', ')}
											</span>
										</div>
									)}
									{answer ? (
										question.type === 'date' ? (
											<div className={styles.answer}>
												<ArrowIcon />
												{format(new Date(answer.toString()), 'd MMMM yyyy', {
													locale: fr,
												})}
											</div>
										) : question.type === 'media' &&
										  question.media.mediaType === 'photo' ? (
											<div className={styles.answer}>
												<img src={answer.toString()} alt="Media" />
											</div>
										) : (
											<div className={styles.answer}>
												<ArrowIcon />
												{answer.toString()}
											</div>
										)
									) : (
										<div className={styles.noAnswer}>
											<ErrorIcon />
											Absence de réponse
										</div>
									)}
									<ProgressBar
										currentQuestionId={question.questionId}
										chapter={chapter}
										answers={answers}
									/>
								</div>
								{question.context && (
									<div className={`${styles.greyText} ${styles.textColumn}`}>
										<span>Contexte de la question</span>
										<div>{question.context}</div>

										<div className={styles.contextMedia}>
											{/* {question?.contextMedia?.map(
												(media: any, index: number) => (
													<img
														key={index}
														className={styles.contextImg}
														src={media}
														alt="Media"
													/>
												)
											)} */}
											{question.imageMedia && (
												<img
													className={styles.contextImg}
													src={question.imageMedia}
													alt="Media"
												/>
											)}
										</div>
									</div>
								)}
							</div>
							<div className={styles.footer}>
								<div>
									{index + summaryPages.length + 1} /{' '}
									{flattenQuestions.length + summaryPages.length}
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}
