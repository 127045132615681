import { useState } from 'react';
import { useGetAnswerQuery } from '../../store/api';
// import { renderToString } from 'react-dom/server';
import { Checklist as ChecklistType } from '../../types';
import { ReactComponent as EyeIcon } from '../icons/eye.svg';
import PdfReport from './PdfReport';
// import config from '../../config';
import styles from './PdfReport.module.scss';
import { useDeviceType } from 'useDeviceType';

// const baseUrl = config.baseUrl;

export default function PdfGenerator({
	response,
	id,
}: {
	response: {
		state: ChecklistType;
		timestamp: string;
		responseId: string;
		modifiedBy: {
			userId: string;
			lastName: string;
			firstName: string;
			timestamp: string;
		}[];
	};
	id: string;
}) {
	const { isTypeOrLarger } = useDeviceType();
	const [visualizePdf, setVisualizePdf] = useState(false);
	// const [loading, setLoading] = useState(false);
	const { data } = useGetAnswerQuery(response.responseId, {
		skip: !response.responseId,
	});

	// const handleDownload = async () => {
	// 	try {
	// 		setLoading(true);

	// 		if (!data) {
	// 			throw new Error('No data to generate PDF');
	// 		} else {
	// 			const htmlContent = renderToString(
	// 				<PdfReport response={response} answers={data} />
	// 			);
	// 			const pdfResponse = await fetch(`${baseUrl}/pdf/generate`, {
	// 				method: 'POST',
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					Authorization: `Bearer ${localStorage.getItem('token')}`,
	// 				},
	// 				body: JSON.stringify({ html: htmlContent }),
	// 			});

	// 			if (!pdfResponse.ok) {
	// 				throw new Error('Erreur lors de la génération du PDF');
	// 			}

	// 			const pdfBlob = await pdfResponse.blob();
	// 			const url = window.URL.createObjectURL(pdfBlob);
	// 			const link = document.createElement('a');
	// 			link.href = url;
	// 			link.setAttribute('download', 'rapport.pdf');
	// 			document.body.appendChild(link);
	// 			link.click();
	// 			link.parentNode?.removeChild(link);
	// 		}
	// 	} catch (error) {
	// 		logger.error('Erreur lors du téléchargement du PDF :', error);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setVisualizePdf(false);
		}
	};

	return (
		<div>
			{isTypeOrLarger('md') && (
				<div className={styles.buttonsFlex}>
					{/* <button onClick={handleDownload} disabled={loading}>
					{loading ? 'Génération...' : 'Download PDF'}
				</button> */}
					<button
						className={styles.greyButton}
						onClick={() => setVisualizePdf(true)}
						// disabled={loading}
					>
						<EyeIcon />
						Visualiser le PDF
					</button>
				</div>
			)}

			{/* {loading && <div className={styles.loader}>Loading...</div>} */}

			{visualizePdf && (
				<div onClick={handleBackgroundClick} className={styles.darkBackground}>
					<div
						className={styles.pdfContainer}
						onClick={(e) => e.stopPropagation()}
					>
						{' '}
						{data && (
							<PdfReport response={response} answers={data} hasSpacing />
						)}
					</div>
				</div>
			)}
		</div>
	);
}
