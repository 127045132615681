import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import socket from '../../store/socket';
import config from '../../config';
// Api
import { useGetFolderQuery, useGetFoldersQuery } from '../../store/api';
// Components
import CoverImage from 'components/shared/CoverImage/CoverImage';
import Navbar from 'components/Navbar/Navbar';
import SimplePopup from 'components/shared/SimplePopup/SimplePopup';
import { useNavigation } from '../../NavigationContext';
// Icons
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as AddDownIcon } from '../icons/arrow_down.svg';
import { ReactComponent as ChecklistIcon } from '../icons/checklist.svg';
import { ReactComponent as DuplicateIcon } from '../icons/duplicate.svg';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as LeftArrowIcon } from '../icons/arrow_back.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
// Slices
import { setToast } from 'components/Toast/toastSlice';
// Styles
import styles from './Home.module.scss';
// Types
import { Checklist as ChecklistType } from '../../types';

export default function Folder() {
	const dispatch = useDispatch();
	const { navigation, setNavigation, setOpenedChecklistId } = useNavigation();
	const { data: folders } = useGetFoldersQuery();
	const [inputValue, setInputValue] = useState<string>('');
	const [checklistId, setChecklistId] = useState<string>('');
	const [onFolderPopup, setOnFolderPopup] = useState<{
		show: boolean;
		title: string;
		top: number;
		left: number;
		height: number;
		data: any[];
		dividerItem?: number | null;
	}>({
		show: false,
		title: '',
		top: 0,
		left: 0,
		height: 0,
		data: [],
		dividerItem: null,
	});

	const folderName =
		folders?.find((folder) => folder.folderId === navigation.split('_')[1])
			?.name ?? '';

	useEffect(() => {
		document.title = `Dossier - ${folderName}`;
	}, [folderName]);

	const { data: checklists, refetch } = useGetFolderQuery(
		{ id: navigation.split('_')[1] ?? '', q: inputValue || undefined },
		{
			skip: !navigation.split('_')[1],
			refetchOnMountOrArgChange: true,
		}
	);

	const handleNavigateToChecklist = (checklistId: string) => {
		setNavigation(`checklist_${checklistId}`);
		setOpenedChecklistId(checklistId);
	};

	const handleCreateChecklist = () => {
		socket.emit('createChecklist', {
			folderId: navigation.split('_')[1],
			title: 'Nouvelle checklist',
		});
	};

	const handleUpdateFolder = (name: string) => {
		socket.emit('updateFolder', { folderId: navigation.split('_')[1], name });
	};

	const handleDeleteFolder = () => {
		socket.emit('deleteFolder', navigation.split('_')[1]);
		setNavigation('home');
	};

	const handleDeleteChecklist = useCallback(() => {
		socket.emit('deleteChecklist', checklistId);
		resetPopup();
		refetch();
	}, [checklistId, refetch]);

	const handleDuplicateCL = useCallback(() => {
		socket.emit('duplicateChecklist', checklistId);
	}, [checklistId]);

	const resetPopup = () => {
		setChecklistId('');
		setOnFolderPopup({
			show: false,
			title: '',
			top: 0,
			left: 0,
			height: 0,
			data: [],
		});
	};

	const copyToClipboard = useCallback(
		async (folder: boolean) => {
			try {
				const param = folder
					? `folder=${navigation.split('_')[1]}`
					: `checklist=${checklistId}`;
				const link = `${config.baseUrl
					.replace('/api', '')
					.replace('localhost:3000', 'localhost:3001')}/?${param}`;

				await navigator.clipboard.writeText(link);
				dispatch(
					setToast({
						message: 'Lien copié dans votre presse-papier',
						copyClipboard: false,
						success: true,
					})
				);
			} catch (err) {
				// setCopySuccess('Failed to copy!');
			}
		},
		[checklistId, dispatch, navigation]
	);

	return (
		<div className={styles.home}>
			<Navbar
				before={
					<button
						onClick={() => setNavigation('home')}
						className={styles.noBorderButton}
					>
						<LeftArrowIcon />
					</button>
				}
			/>
			<div
				className={`${styles.homeContent} ${
					!checklists?.length ? styles.fixedHeight : ''
				}`}
			>
				<div className={styles.flex}>
					<div className={styles.homeItemContainer}>
						<ChecklistIcon />
					</div>
					<div className={styles.homeItemText}>
						{`${checklists?.length} Checklist${
							checklists?.length && checklists?.length === 1 ? '' : 's'
						}`}
						<div
							className={`${styles.h2Input} ${
								onFolderPopup.title === 'Actions sur ce dossier'
									? styles.active
									: ''
							}`}
							onClick={(e) => {
								setOnFolderPopup({
									show: true,
									title: 'Actions sur ce dossier',
									top:
										e.currentTarget.getBoundingClientRect().top +
										e.currentTarget.getBoundingClientRect().height,
									left: e.currentTarget.getBoundingClientRect().left,
									height: e.currentTarget.getBoundingClientRect().height,
									data: [
										{
											name: `${folderName}`,
											type: 'input',
										},
										{
											icon: <ShareIcon />,
											name: 'Partager',
										},
									],
								});
							}}
						>
							<h2>{folderName}</h2>
							<div className={styles.iconContainer}>
								<AddDownIcon />
							</div>
						</div>
					</div>
					<div className={styles.homeItemButtonContainer}>
						{checklists?.length ? (
							<button
								onClick={handleCreateChecklist}
								className={styles.iconButton}
							>
								<AddIcon fill="#FFFFFF" />
								Nouvelle Checklist
							</button>
						) : null}
					</div>
				</div>

				<div
					className={`${styles.content} ${
						!checklists?.length ? styles.heightAuto : ''
					}`}
				>
					<div className={`${styles.search} ${styles.searchW100}`}>
						<input
							className={styles.input}
							type="text"
							placeholder="Rechercher une Checklist"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
						/>
					</div>

					{checklists?.length ? (
						<div className={styles.checklistItemContainer}>
							{checklists.map((checklist: ChecklistType) => (
								<div
									key={checklist.checklistId}
									className={`${styles.checklistItem} ${
										checklistId === checklist.checklistId ? styles.active : ''
									}`}
									onClick={() =>
										handleNavigateToChecklist(checklist.checklistId)
									}
								>
									<CoverImage
										big
										id={checklist.checklistId}
										coverImage={checklist.coverImage}
									/>
									<div className={styles.checklistInfo}>
										<h5>{checklist.title}</h5>
									</div>
									<div
										className={`${styles.moreCLIcon} ${
											checklistId === checklist.checklistId
												? styles.activeIcon
												: ''
										}`}
										onClick={(e) => {
											e.stopPropagation();
											setChecklistId(checklist.checklistId);
											setOnFolderPopup({
												show: true,
												title: 'Actions sur cette Checklist',
												top:
													e.currentTarget.getBoundingClientRect().top +
													e.currentTarget.getBoundingClientRect().height,
												left: e.currentTarget.getBoundingClientRect().left,
												height: e.currentTarget.getBoundingClientRect().height,
												data: [
													{
														icon: <ShareIcon />,
														name: 'Inviter à remplir',
													},
													{
														icon: <EditIcon />,
														name: 'Éditer',
													},
													{
														icon: <DuplicateIcon />,
														name: 'Dupliquer',
													},
													{
														icon: <ShareIcon />,
														name: 'Partager',
													},
												],
												dividerItem: 1,
											});
										}}
									>
										<MoreIcon />
									</div>
								</div>
							))}
						</div>
					) : (
						<div className={styles.emptyData}>
							<div className={styles.emptyDataInfo}>
								<h2>Aucune Checklist dans ce dossier</h2>
								<div className={styles.description}>
									Lancez-vous pour être le premier à en créer une !
								</div>
							</div>
							<button
								onClick={handleCreateChecklist}
								className={`${styles.iconButton} ${styles.fitContentWidth}`}
							>
								<AddIcon fill="#FFFFFF" />
								Créer une nouvelle Checklist
							</button>
						</div>
					)}
				</div>

				{onFolderPopup.show && (
					<SimplePopup
						deleteOption
						top={onFolderPopup.top}
						left={onFolderPopup.left}
						height={onFolderPopup.height}
						title={onFolderPopup.title}
						data={onFolderPopup.data}
						{...(onFolderPopup.dividerItem !== null && {
							dividerItem: onFolderPopup.dividerItem,
						})}
						handleDelete={(title) => {
							if (title.includes('dossier')) {
								handleDeleteFolder();
							} else {
								handleDeleteChecklist();
							}
						}}
						handleInputChange={handleUpdateFolder}
						handleActions={(title, name) => {
							const type = name.toLowerCase();
							if (type.includes('remplir') || type.includes('partager')) {
								copyToClipboard(title.includes('dossier') ? true : false);
							} else if (type.includes('dupliquer')) {
								handleDuplicateCL();
							} else if (type.includes('éditer')) {
								handleNavigateToChecklist(checklistId);
							}
							resetPopup();
						}}
						close={resetPopup}
					/>
				)}
			</div>
		</div>
	);
}
