import {Question as QuestionType} from '../../../types';
import styles from './MultipleSelect.module.scss';
import {useMemo} from 'react';

export default function SingleSelect({
	question,
	value,
	onChange,
	goToNextQuestion,
}: {
	question: QuestionType;
	value: string;
	onChange: (value: string) => void;
	goToNextQuestion: () => void;
}) {
	const handleSelectOption = (option: string) => {
		onChange(option === value ? '' : option);
		option !== value && goToNextQuestion();
	};

	const shuffledOptions = useMemo(() => {
		return [...(question.singleSelect?.options || [])].sort(
			() => Math.random() - 0.5
		);
	}, [question.singleSelect?.options]);

	return (
		<div className={styles.multipleSelect}>
			{shuffledOptions.map((option, index) => (
				<div
					className={`${styles.answer} ${
						value === option ? styles.selectedAnswer : ''
					}`}
					onClick={() => handleSelectOption(option)}
					key={index}
				>
					{option}
					<div className={styles.bubble}></div>
				</div>
			))}
		</div>
	);
}
