import { useState, useEffect } from 'react';
import { useLoginMutation, useSignupMutation } from './store/userApi';
import { ReactComponent as EyeClosedIcon } from 'components/icons/eye_closed.svg';
import { ReactComponent as EyeIcon } from 'components/icons/eye.svg';
import { ReactComponent as Logo } from 'components/icons/logo.svg';
import loginImg from 'components/icons/login_img.png';

import styles from './LoginForm.module.scss';

interface SignupFormProps {
	setIsAuthenticated: (isAuthenticated: boolean) => void;
}

function SignupForm({ setIsAuthenticated }: SignupFormProps) {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [signup] = useSignupMutation();

	const handleRegister = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const response = await signup({
				email,
				password,
				firstName,
				lastName,
			}).unwrap();
			if (response.token) {
				localStorage.setItem('token', response.token);
				setIsAuthenticated(true);
			}
			window.location.reload();
			window.location.href = '/';
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<form onSubmit={handleRegister} className={styles.register}>
			<div>
				<input
					type="text"
					placeholder="Prénom"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					required
				/>
				<input
					type="text"
					placeholder="Nom"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					required
				/>
			</div>
			<div>
				<label htmlFor="signup-email">Email:</label>
				<input
					type="email"
					id="signup-email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
			</div>
			<div>
				<label htmlFor="signup-password">Mot de passe:</label>
				<input
					type="password"
					id="signup-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
			</div>
			<button type="submit">S'inscrire</button>
		</form>
	);
}

export default function LoginForm({
	setIsAuthenticated,
}: {
	setIsAuthenticated: (isAuthenticated: boolean) => void;
}) {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [passwordShown, setPasswordShown] = useState(false);
	const [login, { isLoading }] = useLoginMutation();
	const [showSignup, setShowSignup] = useState(false);

	useEffect(() => {
		document.title = 'Soop CL | Se connecter';
		setShowSignup(window.location.href.includes('signup'));
	}, []);

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			setIsAuthenticated(true);
		}
	}, [setIsAuthenticated]);

	const togglePasswordVisibility = () => {
		setPasswordShown((prevState) => !prevState);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const response = await login({ email, password }).unwrap();
			if (response.token) {
				localStorage.setItem('token', response.token);
				setIsAuthenticated(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.loginSection}>
					<div className={styles.login}>
						<div className={styles.logo}>
							<Logo />
						</div>
						<div className={styles.form}>
							<div className={styles.title}>
								{showSignup ? 'Inscription' : 'Connexion'}
							</div>

							<>
								<form onSubmit={handleSubmit} className={styles.formContainer}>
									<div className={styles.group}>
										<label className={styles.inputTitle} htmlFor="email">
											Votre adresse email
										</label>
										<input
											id="email"
											type="email"
											value={email}
											className={styles.loginInput}
											onChange={(e) => setEmail(e.target.value)}
											placeholder="entreprise@gmail.com"
											autoComplete="current-email"
											required
										/>
									</div>
									<div className={styles.group}>
										<label className={styles.inputTitle} htmlFor="password">
											Mot de passe
										</label>
										<div className={styles.pwdInputContainer}>
											<input
												id="password"
												type={passwordShown ? 'text' : 'password'}
												value={password}
												className={styles.loginInput}
												onChange={(e) => setPassword(e.target.value)}
												placeholder="Saisir votre mot de passe"
												autoComplete="current-password"
												required
											/>
											{passwordShown ? (
												<EyeClosedIcon
													onClick={togglePasswordVisibility}
													className={styles.eyeIcon}
												/>
											) : (
												<EyeIcon
													onClick={togglePasswordVisibility}
													className={styles.eyeIcon}
												/>
											)}
										</div>
									</div>
									<div className={styles.divider}>
										<button type="submit" className={styles.continueButton}>
											{!isLoading ? (
												'Me connecter'
											) : (
												<div className={styles.spinner} />
											)}
										</button>
									</div>
								</form>
								<div className={styles.forgotPassword}>
									Mot de passe oublié ? <span>Réinitialiser par email</span>
								</div>
							</>
						</div>
						{showSignup && (
							<SignupForm setIsAuthenticated={setIsAuthenticated} />
						)}
					</div>
				</div>
				<div className={styles.desktopSection}>
					<h5
						className={styles.imgCopyRight}
						onClick={() =>
							window.open(
								'https://unsplash.com/fr/photos/fumee-bleue-sur-fond-noir-egWTpKFu8rU?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash',
								'_blank'
							)
						}
					>
						Photo de <span>Simon Lee</span> sur <span>Unsplash</span>
					</h5>
					<img src={loginImg} alt="Fumée bleue sur fond noir" />
				</div>
			</div>
		</>
	);
}
