import React from 'react';
import {Question as QuestionType} from '../../../types';
import TitleInput from 'components/TitleInput/TitleInput';
import styles from './Settings.module.scss';

export default function Text({
	question,
	value,
	onChange,
}: {
	question: QuestionType;
	value: string;
	onChange: (value: string) => void;
}) {
	return (
		<div className={styles.inputContainer}>
			<TitleInput
				mode='grey'
				title={value}
				onChange={onChange}
				placeholder='Saisir votre réponse'
			/>
		</div>
	);
}
