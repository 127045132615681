import React from 'react';
import { ReactComponent as LogoIcon } from '../icons/soopcl.svg';
import styles from './Navbar.module.scss';
import socket from 'store/socket';

export default function Navbar({
	before,
	after,
	border = false,
	isAuthenticated,
	setIsAuthenticated,
}: {
	before?: React.ReactNode;
	after?: React.ReactNode;
	border?: boolean;
	isAuthenticated?: boolean;
	setIsAuthenticated?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const handleLogout = () => {
		if (setIsAuthenticated) {
			localStorage.removeItem('token');
			socket.disconnect();
			setIsAuthenticated(false);
		}
	};

	return (
		<div className={`${styles.navbar} ${border ? styles.border : ''}`}>
			{before ? (
				before
			) : isAuthenticated ? (
				<div className={styles.logo}>
					<LogoIcon />
				</div>
			) : null}
			<div className={styles.flex}>
				{after ? (
					after
				) : isAuthenticated ? (
					<button onClick={handleLogout} className={styles.logoutButton}>
						Déconnexion
					</button>
				) : null}
			</div>
		</div>
	);
}
