import { combineReducers } from '@reduxjs/toolkit';
import calendarReducer from '../components/Calendar/calendarSlice';
import toastReducer from 'components/Toast/toastSlice';
import { api } from './api';
import { userApi } from './userApi';

const rootReducer = combineReducers({
	calendar: calendarReducer,
	toast: toastReducer,
	[api.reducerPath]: api.reducer,
	[userApi.reducerPath]: userApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
