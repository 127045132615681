import { useState, useEffect } from 'react';
import './App.scss';
import Home from './components/Home/Home';
import Folder from './components/Home/Folder';
import LoginForm from './LoginForm';
import ChecklistContainer from './components/ChecklistContainer';
import Toast from 'components/Toast/Toast';
import { NavigationProvider, useNavigation } from './NavigationContext';
import { useGetResponseQuery } from './store/api';
import ReactGA from 'react-ga4';

if (process.env.NODE_ENV === 'production') {
	const TRACKING_ID = process.env.REACT_APP_TRACKING_ID as string;
	ReactGA.initialize(TRACKING_ID);
}

const Routes = ({
	setIsAuthenticated,
	isAuthenticated,
}: {
	setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
	isAuthenticated: boolean;
}) => {
	const {
		navigation,
		openedChecklistId,
		setOpenedChecklistId,
		openedResponse,
		setOpenedResponse,
		setResponseMode,
		setNavigation,
	} = useNavigation();
	const [urlCLId, setUrlCLId] = useState<string | null>(null);
	const [urlFolderId, setUrlFolderId] = useState<string | null>(null);
	const [urlResponseId, setUrlResponseId] = useState<string | null>(null);
	const { data: urlResponse } = useGetResponseQuery(urlResponseId || '', {
		skip: !urlResponseId,
	});

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const responseId = urlParams.get('response');
		const folderId = urlParams.get('folder');
		const checklistId = urlParams.get('checklist');
		if (responseId) {
			setUrlResponseId(responseId);
		} else if (folderId) {
			setUrlFolderId(folderId);
		} else if (checklistId) {
			setUrlCLId(checklistId);
		}
	}, []);

	useEffect(() => {
		if (urlCLId) {
			setOpenedChecklistId(urlCLId);
			setNavigation(`checklist_${urlCLId}`);
		} else if (urlFolderId) {
			setNavigation(`folder_${urlFolderId}`);
		} else if (urlResponse) {
			setOpenedResponse(urlResponse);
			setOpenedChecklistId(urlResponse.checklistId);
			setNavigation(`checklist_${urlResponse.checklistId}`);
			setResponseMode(true);
		}
	}, [
		urlCLId,
		urlFolderId,
		urlResponse,
		setResponseMode,
		setOpenedResponse,
		setOpenedChecklistId,
		setNavigation,
	]);

	return (
		<>
			{navigation === 'home' && (
				<Home
					setIsAuthenticated={setIsAuthenticated}
					isAuthenticated={isAuthenticated}
				/>
			)}
			{navigation.startsWith('folder') && <Folder />}
			{(navigation.startsWith('checklist') ||
				navigation.startsWith('chapter') ||
				navigation.startsWith('question')) && (
				<>
					{openedChecklistId && (
						<ChecklistContainer
							id={openedChecklistId}
							response={openedResponse ?? undefined}
						/>
					)}
				</>
			)}
		</>
	);
};

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	return (
		<div className="App">
			<Toast />
			{!isAuthenticated ? (
				<LoginForm setIsAuthenticated={setIsAuthenticated} />
			) : (
				<NavigationProvider>
					<Routes
						setIsAuthenticated={setIsAuthenticated}
						isAuthenticated={isAuthenticated}
					/>
				</NavigationProvider>
			)}
		</div>
	);
}

export default App;
