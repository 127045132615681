import { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useUploadFilesMutation, useDeleteFileMutation } from '../store/api';
import { ReactComponent as AddImageIcon } from './icons/add_image.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import styles from './Upload.module.scss';
import imageCompression from 'browser-image-compression';

const DEBOUNCE_DELAY = 300;
const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15 MB

export const renderFile = (url: string) => {
	const renderImage = (src: string) => (
		<img
			src={src}
			alt="File preview"
			style={{ maxWidth: '200px', maxHeight: '200px' }}
			onError={(e) => {
				console.error('Error loading image:', e);
				e.currentTarget.style.display = 'none';
			}}
		/>
	);

	const renderVideo = (src: string) => (
		<video
			src={src}
			controls
			style={{ maxWidth: '200px', maxHeight: '200px' }}
		/>
	);

	if (url.startsWith('blob:')) {
		// Handle blob URLs
		return (
			<object
				data={url}
				type="application/octet-stream"
				style={{ maxWidth: '200px', maxHeight: '200px' }}
			>
				<embed src={url} type="application/octet-stream" />
			</object>
		);
	} else {
		const extension = url.split('.').pop()?.toLowerCase();
		if (
			['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension || '') ||
			url.startsWith('https://staging-soopcl.s3')
		) {
			return renderImage(url);
		} else if (['mp4', 'webm', 'ogg'].includes(extension || '')) {
			return renderVideo(url);
		} else {
			return (
				<a href={url} target="_blank" rel="noopener noreferrer">
					{url}
				</a>
			);
		}
	}
};

type UploadProps<T> = {
	value: T;
	mode?: 'photo' | 'video' | 'file';
	onChange: (value: T) => void;
	maxFiles?: number;
	buttonLabel?: string;
	fileTitle?: string[];
	setFileTitle?: (title: string[]) => void;
	setMediaAndTitle?: (media: string[], title: string[]) => void;
	hide?: boolean;
	replace?: boolean;
};

export function Upload<T extends string | string[]>({
	value,
	onChange,
	mode = 'photo',
	maxFiles = 1,
	buttonLabel,
	fileTitle,
	setFileTitle,
	setMediaAndTitle,
	hide,
	replace = false,
}: UploadProps<T>) {
	const [uploadFiles, { isLoading, isError, error }] = useUploadFilesMutation();
	const [deleteFile] = useDeleteFileMutation();
	const [uploadedFileUrls, setUploadedFileUrls] = useState<string[]>([]);
	const [localFileTitles, setLocalFileTitles] = useState<string[]>([]);
	const titleRefs = useRef<(NodeJS.Timeout | null)[]>([]);

	const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			const compressedFiles = await Promise.all(
				Array.from(files).map(async (file) => {
					if (file.size > MAX_FILE_SIZE) {
						alert(
							`Les fichiers doivent être inférieurs à ${
								MAX_FILE_SIZE / (1024 * 1024)
							}MB`
						);
						return null;
					}

					if (file.type.startsWith('image/')) {
						const options = {
							maxSizeMB: 1,
							maxWidthOrHeight: 1920,
							useWebWorker: true,
						};
						try {
							return await imageCompression(file, options);
						} catch (error) {
							console.error('Compression failed:', error);
							return file;
						}
					}
					return file;
				})
			);

			const validFiles = compressedFiles.filter((file) => file !== null);
			const formData = new FormData();
			validFiles.forEach((file) => {
				formData.append('files', file as Blob);
			});

			try {
				const result = await uploadFiles(formData).unwrap();
				if (result && result.files) {
					const fileUrls = result.files.map((file) => `${file.url}`);
					let newFileUrls: string | string[];
					if (replace && maxFiles === 1) {
						// if (uploadedFileUrls[0]) {
						// 	await deleteFile({ url: uploadedFileUrls[0] }).unwrap();
						// }
						newFileUrls = fileUrls[0];
						setUploadedFileUrls([newFileUrls]);
						setLocalFileTitles(['']);
					} else {
						newFileUrls = [...(uploadedFileUrls || []), ...fileUrls];
						setUploadedFileUrls(newFileUrls);
						setLocalFileTitles([
							...localFileTitles,
							...new Array(fileUrls.length).fill(''),
						]);
					}
					onChange(newFileUrls as T);
				}
			} catch (err) {
				console.error('Failed to upload files:', err);
			}
		}
	};

	const handleRemoveFile = async (url: string, index: number) => {
		try {
			await deleteFile({ url }).unwrap();
			const newFileUrls = [...uploadedFileUrls];
			newFileUrls.splice(index, 1);
			setUploadedFileUrls(newFileUrls);
			const newTitles = [...localFileTitles];
			newTitles.splice(index, 1);
			setLocalFileTitles(newTitles);
			if (setFileTitle) {
				const newFileTitle = [...(fileTitle || [])];
				newFileTitle.splice(index, 1);
				setMediaAndTitle?.(newFileUrls, newFileTitle);
			}
		} catch (err) {
			console.error('Failed to delete file:', err);
		}
	};

	const handleTitleChange = (value: string, index: number) => {
		const newTitles = [...localFileTitles];
		newTitles[index] = value;
		setLocalFileTitles(newTitles);

		if (setFileTitle) {
			if (titleRefs.current[index]) {
				clearTimeout(titleRefs.current[index] as NodeJS.Timeout);
			}

			titleRefs.current[index] = setTimeout(() => {
				setFileTitle(newTitles);
			}, DEBOUNCE_DELAY);
		}
	};

	const fileTitleRef = useRef(fileTitle);

	useEffect(() => {
		fileTitleRef.current = fileTitle;
	}, [fileTitle]);

	useEffect(() => {
		if (value) {
			const prefixedUrls = (Array.isArray(value) ? value : [value]).map(
				(url) => url
			);
			setUploadedFileUrls(prefixedUrls);
			setLocalFileTitles(
				(fileTitleRef.current || []).slice(0, prefixedUrls.length)
			);
		}
	}, [value, fileTitle]);

	const remainingSlots = maxFiles - uploadedFileUrls.length;

	if (hide)
		return (
			<>
				<input
					type="file"
					id="file-input"
					multiple={maxFiles !== 1 && remainingSlots > 1}
					accept={
						mode === 'photo'
							? 'image/*'
							: mode === 'video'
							? 'video/*'
							: undefined
					}
					onChange={handleFileChange}
					disabled={isLoading}
					style={{ display: 'none' }}
				/>
				<label
					htmlFor="file-input"
					className={`${styles.greyButton} ${
						remainingSlots <= 0 && !fileTitle ? styles.disabled : ''
					}`}
				>
					<AddImageIcon />
					{buttonLabel
						? buttonLabel
						: `Ajouter ${
								mode === 'photo'
									? 'une photo'
									: mode === 'video'
									? 'une vidéo'
									: 'un fichier'
						  }`}
				</label>
			</>
		);

	return (
		<div className={`${styles.container} ${fileTitle ? styles.context : ''}`}>
			{uploadedFileUrls?.length ? (
				<div className={styles.imagesContainer}>
					{uploadedFileUrls.map((url, index) => (
						<div key={index} className={`${styles.section}`}>
							<div
								className={`${styles.imageContainer} ${
									fileTitle ? styles.withTitle : ''
								}`}
							>
								{!fileTitle && (
									<div
										onClick={() => handleRemoveFile(url, index)}
										className={styles.deleteIcon}
									>
										<CloseIcon />
									</div>
								)}
								{renderFile(url)}
							</div>
							{fileTitle && (
								<div className={styles.content}>
									<div>Titre de l'image (optionnel)</div>
									<input
										value={localFileTitles[index]}
										onChange={(e) => {
											const newContextMediaTitle = [...(fileTitle || [])];
											newContextMediaTitle[index] = e.target.value;
											handleTitleChange(e.target.value, index);
										}}
										className={styles.nameInput}
										type="text"
										placeholder="Saisir le titre de l'image"
									/>
									<div
										onClick={() => handleRemoveFile(url, index)}
										className={styles.delete}
									>
										Supprimer
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			) : null}

			<input
				type="file"
				id="file-input"
				multiple={maxFiles !== 1 && !replace}
				accept={
					mode === 'photo'
						? 'image/*'
						: mode === 'video'
						? 'video/*'
						: undefined
				}
				onChange={handleFileChange}
				disabled={isLoading}
				style={{ display: 'none' }}
			/>
			<label
				htmlFor="file-input"
				className={`${styles.noBorderButton} ${
					isLoading ? styles.disabled : ''
				}`}
			>
				<AddImageIcon />
				{buttonLabel
					? buttonLabel
					: `Ajouter ${
							mode === 'photo'
								? 'une photo'
								: mode === 'video'
								? 'une vidéo'
								: 'un fichier'
					  }`}
			</label>
			{isError && (
				<p>
					Error uploading files:{' '}
					{(error as { data: { message: string } }).data?.message}
				</p>
			)}
		</div>
	);
}
